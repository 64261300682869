import {examUrl} from "../http/baseUrl";
import {get,post,del,put} from "../http/http";

// 获取主营产品技术领域：enterpriseEval/getTechnologyField
// 基本信息（下一步、暂存）：enterpriseEval/confirmBase
// 企业知识产权状况（下一步、暂存）：enterpriseEval/confirmIp
// 企业账务状况（下一步、暂存）：enterpriseEval/confirmFinance
// 填写企业研发管理现状（下一步、暂存）： enterpriseEval/confirmRdManage

export const getTechnologyField = () => {
  return get(`${examUrl}/enterpriseEval/getTechnologyField`);
}

export const getProvince = () => {
  return get(`${examUrl}/enterpriseEval/getProvince`);
}

export const getNewProvince = () => {
  return get(`${examUrl}/enterpriseEval/getNewProvince`);
}

export const getByParentCode=(params)=> {
  return get(`${examUrl}/enterpriseEval/getByParentCode`,params);
}
export const confirmBase = (data) => {
  return post(`${examUrl}/enterpriseEval/confirmBase`,data)
}

export const confirmIp = (data) => {
  return post(`${examUrl}/enterpriseEval/confirmIp`,data)
}

export const confirmFinance = (data) => {
  return post(`${examUrl}/enterpriseEval/confirmFinance`,data)
}


export const confirmRdManage = (data) => {
  return post(`${examUrl}/enterpriseEval/confirmRdManage`,data)
}

export const getReport = (data) => {
  return get(`${examUrl}/enterpriseEval/report`,data);
}

export const getEnterpriseInfo =(data) => {
  return get(`${examUrl}/enterpriseEval/getEnterprise`,data);
}

export const getEvalId = (data) => {
  return get(`${examUrl}/enterpriseEval/getRecentlyEId`,data)
}


export const getBaseInfo =(data) => {
  return get(`${examUrl}/enterpriseEval/getConfirmBase`,data);
}

export const getSecondInfo =(data) => {
  return get(`${examUrl}/enterpriseEval/getConfirmIp`,data);
}

export const getThirdInfo = (data) => {
  return get(`${examUrl}/enterpriseEval/getConfirmFinance`,data);
}

export const getFourthInfo = (data) => {
  return get(`${examUrl}/enterpriseEval/getConfirmRdManage`,data);
}